<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        title="Verfügbare Sonderangebote"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">{{ voMsg('search.offers.available') }}</span>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});
    	},
    	methods: {
    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},

    	},
    	computed: {
    		getOfferText() {
    			let offerText = '';
    			const cur = VOFFICE.currency || '&euro;';
    			if (this.offers) {
    				// Erstelle eine Kopie des Arrays und sortiere diese Kopie
    				const sortedOffers = [...this.offers].sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				offerText = "<ul>";
    				for (let offer of sortedOffers) {
    					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
    						offerText += "<li>";
    						offerText += offer.name + " ";

    						if (offer.discountType === 'PERCENT') {
    							offerText += `- ${offer.value / 100}% ${this.voMsg('tpl.text.rabatt')} `;
    						}

    						if (offer.discountType === 'FLAT') {
    							offerText += `- ${offer.value / 100} ${cur} ${this.voMsg('tpl.text.rabatt')} `;
    						}

    						if (offer.type === 'EARLYBIRD') {
    							offerText += offer.timeSpan === 1 ? this.voMsg('tpl.offer.earlybird.arrival') + " " : `${this.voMsg('tpl.offer.earlybird.arrival.pl')} ${offer.timeSpan} ${this.voMsg('tpl.offer.earlybird.days')} `;
    						}

    						if (offer.type === 'LASTMINUTE') {
    							offerText += offer.timeSpan === 1 ? this.voMsg('tpl.search.offer.lastminute.arrival') + " " : `${this.voMsg('tpl.search.offer.lastminute.arrival.pl')} ${offer.timeSpan} ${this.voMsg('tpl.text.days')} `;
    						}

    						if (offer.fromdate) {
    							offerText += `${this.voMsg('tpl.text.from')} ${DateEx.formatDate(offer.fromdate, undefined, VOFFICE.mlang)} `;
    						}
    						if (offer.tilldate) {
    							offerText += `${this.voMsg('tpl.text.until')} ${DateEx.formatDate(offer.tilldate, undefined, VOFFICE.mlang)} `;
    						}

    						if (offer.minStay > 1) {
    							offerText += `(${this.voMsg('tpl.min')} ${offer.minStay} ${this.voMsg('tpl.text.nights')})`;
    						}

    						offerText += "</li>";
    					}
    				}
    				offerText += "</ul>";
    			}

    			return offerText;
    		}
    	}

    };
</script>